
 .fade-in {
    animation: fade-in 1.9s ease 500ms backwards
 }

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opactiy: 1;
    }
}

.left-slide {
    animation: left-slide .7s ease 800ms backwards
 }

 .right-slide {
    animation: right-slide .8s ease 700ms backwards
 } 

@keyframes left-slide {
    0% {
        opacity: 0;
        transform: translateX(20px);
        }
    100% {
        opacity: 1;
        transform: translate(0px);
    }
}

@keyframes right-slide {
    0% {
        opacity: 0;
        transform: translateX(-20px);
        }
    100% {
        opacity: 1;
        transform: translate(0px);
    }
}